<template>
  <div>
    <base-modal-ex :active.sync="isActive"
      :animation-in="animationIn"
      :animation-out="animationOut"
      :animation-duration="animationDuration"
      :can-cancel="false"
      :min-width="60"
      has-modal-card>
      <header class="modal-card-head">
        <p class="modal-card-title"
          :class="{ 'is-flex-items-center' : showIcon}">
          <span>Add or Edit Category</span>
        </p>
      </header>
      <section class="modal-card-body">

        <div class="columns">
          <div class="column">
            <label>Create New Category</label>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <input type="text"
              class="input"
              v-model="newCategoryName"
              placeholder="Please enter a new Name">
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div class="button is-secondary"
              @click="newCategoryName = ''">Clear</div>
          </div>
          <div class="
            column">
            <div class="button is-primary is-pulled-right"
              @click="createNewCategory()">Create</div>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <label>Edit Existing Category</label>
          </div>
        </div>

        <draggable :list="categories"
          group="categories"
          handle=".item-drag-handle"
          :move="checkMove"
          @change="(event)=>onImageCategoryOrderChange(event)">
          <div class="field is-horizontal"
            v-for="(category, index) in categories"
            :key="category.quoteAttachmentCategoryId">
            <div class="control">
              <span class="icon pt-2">
                <i class="mdi mdi-drag mdi-24px item-drag-handle"
                  v-if="index !=0"
                  aria-hidden="true" />
              </span>
            </div>
            <div class="field-body">
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input class="input"
                    type="text"
                    v-model="category.name"
                    placeholder="Existing Name"
                    @keyup="setDirty(category)">
                </div>
                <div class="control">
                  <a class="button is-secondary has-text-danger"
                    :class="{ 'is-hidden': category.isDefault }"
                    @click="deleteCategory(category)">
                    <i class="mdi mdi-trash-can mdi-18px" />
                  </a>
                </div>
              </div>
            </div>
            <div v-if="category.isPrivate"
              class="control is-flex is-align-items-center pl-3">
              <span class="icon">
                <i class="mdi mdi-eye-off mdi-24px" />
              </span>
            </div>
          </div>
        </draggable>
      </section>

      <footer class="modal-card-foot">

        <div :style="`width: 100%; display: flex; flex-direction: row-reverse;`">

          <div style="align-self: flex-start;">
            <button class="button tooltip"
              @click="cancel()"
              data-tooltip="Cancel">
              <span>Cancel</span>
            </button>
            <button class="button is-primary tooltip"
              @click="saveCategories()"
              data-tooltip="Save">
              <span>Save</span>
            </button>
          </div>
        </div>
      </footer>
    </base-modal-ex>
    <base-modal-ex :active.sync="isDeleteModalActive"
      :animation-in="animationIn"
      :animation-out="animationOut"
      :animation-duration="animationDuration"
      :can-cancel="false"
      :min-width="60"
      has-modal-card>
      <header class="modal-card-head">
        <p class="modal-card-title"
          :class="{ 'is-flex-items-center' : showIcon}">
          <span>Delete Category</span>
        </p>
      </header>
      <section class="modal-card-body">
        <p>
          <span>Proceed to delete category "<span class="has-text-weight-bold">{{ selectedCategoryName }}</span>" ?</span>
        </p>
      </section>
      <footer class="modal-card-foot">
        <div :style="`width: 100%; display: flex; flex-direction: row-reverse;`">

          <div style="align-self: flex-start;">
            <button class="button tooltip"
              @click="closeDeleteModal()"
              data-tooltip="Cancel">
              <span>Cancel</span>
            </button>
            <button class="button is-primary tooltip"
              @click="confirmDeleteCategory()"
              data-tooltip="Save">
              <span>Confirm</span>
            </button>
          </div>
        </div>
      </footer>
    </base-modal-ex>
  </div>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import QuoteService from '@/views/quote/QuoteService'
import draggable from 'vuedraggable'

const newGuid = '00000000-0000-0000-0000-000000000000'

export default {
  name: 'QuoteImageCategoryModal',
  components: {
    BaseModalEx,
    draggable
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'warning'
    },
    iconName: {
      type: String,
      default: 'mdi-alert-decagram'
    }
  },
  data() {
    return {
      isActive: this.active || false,
      newCategoryName: '',
      categories: [],
      isDeleteModalActive: false,
      selectedCategory: null
    }
  },
  computed: {
    maxImageCategoryOrderIndex() {
      return this.categories.reduce((max, obj) => (obj.orderIndex > max ? obj.orderIndex : max), this.categories[0].orderIndex)
    },
    selectedCategoryName() {
      const category = this.selectedCategory
      if (category && category.name) return category.name
      return ''
    }
  },
  watch: {
    active(value) {
      this.loadQuoteImageCategories()
      this.isActive = value
    }
  },
  created() {},
  mounted() {
    //this.loadQuoteImageCategories()
  },
  methods: {
    async createNewCategory() {
      //await QuoteService.createImageCategory(this.newCategoryName)
      //this.$emit('categories-changed')
      //this.loadQuoteImageCategories()
      this.categories.push({
        isDefault: false,
        isPrivate: false,
        name: this.newCategoryName,
        orderIndex: this.maxImageCategoryOrderIndex + 1,
        isRemove: false,
        quoteImageCategoryId: newGuid
      })
      this.newCategoryName = ''
    },
    deleteCategory(row) {
      this.selectedCategory = row
      this.isDeleteModalActive = true
    },
    async confirmDeleteCategory() {
      if (!this.selectedCategory) return

      const row = this.selectedCategory
      const indexToRemove = this.categories.findIndex((obj) => obj.orderIndex === row.orderIndex)
      if (!row.quoteImageCategoryId || row.quoteImageCategoryId == newGuid) {
        if (indexToRemove !== -1) {
          this.categories.splice(indexToRemove, 1)
        }
      } else {
        const isAttached = await this.isCategoryAttachedImage(row.quoteImageCategoryId)
        if (!isAttached) {
          if (indexToRemove !== -1) {
            this.categories.splice(indexToRemove, 1)
          }
        }
      }

      //await QuoteService.updateQuoteImageCategory(row.quoteImageCategoryId, row.name, true)
      //this.$emit('categories-changed')
      //this.loadQuoteImageCategories()
      //this.$emit('cancel')
      this.closeDeleteModal()
    },
    async saveCategories() {
      this.$showSpinner()

      await QuoteService.updateImageCategories(this.categories)

      this.$emit('categories-changed')
      this.loadQuoteImageCategories()
      this.$hideSpinner()
      this.$emit('cancel')
    },
    async isCategoryAttachedImage(quoteImageCategoryId) {
      const attachedImageCount = await QuoteService.getImageCategoryImagesCount(quoteImageCategoryId)
      if (attachedImageCount > 0) {
        this.$notification.openNotificationWithType('danger', 'Unable to delete', `${attachedImageCount} images attached with category.`)
        return true
      }
      return false
    },
    setDirty(category) {
      category.isDirty = true
    },
    async loadQuoteImageCategories() {
      this.categories = await QuoteService.getQuoteImageCategories()
    },
    cancel() {
      this.$emit('cancel')
    },
    async onImageCategoryOrderChange(event) {
      if (event.moved !== undefined) {
        //console.log(event.moved)
        //var movedItem = event.added.element
        // for (let i = 0; i < this.categories.length; i++) {
        //   this.categories[i].orderIndex = i
        // }
      }
    },
    checkMove: function (e) {
      return e.draggedContext.futureIndex !== 0
    },
    closeDeleteModal() {
      this.selectedCategory = null
      this.isDeleteModalActive = !this.isDeleteModalActive
    }
  }
}
</script>

<style lang="scss" scoped>
.not-draggable {
  cursor: no-drop;
}
.item-drag-handle {
  cursor: move;
}
</style>