<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <div class="columns">
          <div class="column">
            <p class="title">
              <span class="has-badge-child-offset2">Images</span>
              <span v-if="imageCount"
                class="has-badge-rounded has-badge-info has-badge-large"
                :data-badge="imageCount" />
            </p>
            <p class="subtitle">{{ isDropzoneActive? 'Upload images': 'Manage quote images' }}</p>
          </div>
          <div class="column is-narrow">
            <p class="buttons">
              <a v-show="hasSelected"
                class="button tooltip"
                @click="showMoveImageDialog"
                data-tooltip="Move Category">
                <span class="icon has-text-primary">
                  <i class="mdi mdi-folder-move mdi-24px" />
                </span>
              </a>
              <a v-show="hasSelected"
                class="button tooltip"
                @click="showCopyImageDialog"
                data-tooltip="Copy to quote">
                <span class="icon has-text-primary">
                  <i class="mdi mdi-content-copy mdi-24px" />
                </span>
              </a>
              <a v-show="hasSelected"
                class="button tooltip"
                @click="showDeletAllModal"
                data-tooltip="Delete selected">
                <span class="icon has-text-danger">
                  <i class="mdi mdi-delete-sweep mdi-24px" />
                </span>
              </a>
              <a v-show="hasSelected"
                class="button tooltip"
                @click="showDownloadImagesModal"
                data-tooltip="Download selected">
                <span class="icon has-text-primary">
                  <i class="mdi mdi-download mdi-24px" />
                </span>
              </a>
              <a class="button tooltip"
                v-show="!isDropzoneActive"
                @click="setSelectedStatusAll(true)"
                data-tooltip="Select all"
                :disabled="isLoadingImages">
                <span class="icon has-text-success">
                  <i class="mdi mdi-select-all mdi-24px" />
                </span>
              </a>
              <a class="button tooltip"
                v-show="!isDropzoneActive"
                @click="setSelectedStatusAll(false)"
                data-tooltip="Deselect all">
                <span class="icon has-text-grey">
                  <i class="mdi mdi-select-off mdi-24px" />
                </span>
              </a>
              <a v-show="!isDropzoneActive"
                class="button tooltip"
                @click="getThumbnails"
                data-tooltip="Refresh"
                :disabled="isLoadingImages">
                <span class="icon">
                  <i class="mdi mdi-refresh mdi-24px" />
                </span>
              </a>
              <a class="button is-primary"
                v-show="!isDropzoneActive"
                v-if="canEditCategory"
                @click="toggleImageCategory(true)">
                <span class="icon">
                  <i class="mdi mdi-18px mdi-plus" />
                </span>
                <span>Add Categories</span>
              </a>
              <a class="button is-primary"
                v-show="isDropzoneActive && uploadedImage"
                @click="clearUploadedFile(true)">
                <span class="icon">
                  <i class="mdi mdi-18px mdi-close" />
                </span>
                <span>Clear All</span>
              </a>

              <a class="button is-primary"
                @click="uploadImage()">
                <span class="icon">
                  <i class="mdi"
                    :class="{ 'mdi-plus' : !isDropzoneActive, 'mdi-minus' : isDropzoneActive }" />
                </span>
                <span>{{ isDropzoneActive ? 'Close' : 'Add' }}</span>
              </a>
            </p>
          </div>
        </div>
        <div class="field"
          v-show="isDropzoneActive && isMobile.any()">
          <div class="control">
            <div class="select">
              <select v-model="dropzoneOptions.capture">
                <option :value="mobileImageSourceType.Gallery">From Gallery</option>
                <option :value="mobileImageSourceType.Camera">From Camera</option>
              </select>
            </div>
          </div>
        </div>
        <div v-show="isDropzoneActive"
          class="card-image">
          <div>
            <label class="label">Select Image Category</label>
            <div class="select field mb-2">
              <select v-model="selectedCategory"
                @change="clearUploadedFile">
                <option v-for="(cat) in imageCategories"
                  :key="cat.quoteImageCategoryId"
                  :value="cat.quoteImageCategoryId">
                  {{ cat.name }}
                </option>
              </select>
            </div>
          </div>
          <vue-dropzone ref="quoteImageDropzone"
            v-if="isDropzoneActive"
            :id="'quoteImageDropzone'"
            :use-custom-slot="true"
            :options="dropzoneOptions"
            @vdropzone-mounted="dropzoneMounted"
            @vdropzone-file-added="dropzoneFileAdded"
            @vdropzone-success="dropzoneSuccess"
            @vdropzone-error-multiple="dropzoneErrorMultiple"
            @vdropzone-sending="dropzoneSending"
            @vdropzone-total-upload-progress="dropzoneTotalUploadProgress"
            @vdropzone-complete-multiple="dropzoneCompleteMultiple"
            @vdropzone-queue-complete="(file) =>dropzoneQueueComplete(file)" />
        </div>
        <div v-show="!isDropzoneActive">
          <template v-for="category in imageCategories">
            <quote-image-category v-if="refreshComponent"
              :categories="imageCategories"
              :images="imagesWithInfo"
              :key="category.quoteImageCategoryId"
              :quote-id="value.quoteId"
              :category="category.name"
              :category-id="category.quoteImageCategoryId"
              :category-order-index="category.orderIndex"
              :is-private="category.isPrivate"
              :value="value"
              :has-select="hasSeclectAll"
              :refresh="refreshCategory"
              @update-image="updateImage"
              @arrange-images-order="arrangeImagesOrder"
              ref="quoteImageCategories" />
          </template>
        </div>

      </article>
    </div>
    <confirm-modal :active.sync="isDeleteAllModalActive"
      @ok="deleteSelectedImages(true)"
      @cancel="deleteSelectedImages(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete images</p>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold">{{ selectedCount }}</span> selected images will be deleted. Continue?
      </p>
    </confirm-modal>
    <confirm-modal :active.sync="isDownloadImagesModalActive"
      @ok="downloadSelectedImages(true)"
      @cancel="downloadSelectedImages(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Download images</p>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold">{{ selectedCount }}</span> selected images will be downloaded. Continue?
      </p>
    </confirm-modal>
    <quote-image-category-modal :active="addCategoryModalOpen"
      @cancel="toggleImageCategory(false)"
      @categories-changed="onImageCategoriesChanged()" />
    <quote-no-search-modal v-if="isCopyImageModalActive"
      :active.sync="isCopyImageModalActive"
      @copy="copyImagesToQuote"
      @close="closeCopyImageModal" />
    <confirm-modal :active.sync="isMoveSelectedImages"
      @ok="moveSelectedImages(true)"
      @cancel="moveSelectedImages(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Move to category</p>
      <div slot="text-content">
        <label class="label">Select Image Category</label>
        <div class="select field mb-2">
          <select v-model="selectedCategory">
            <option v-for="(cat) in getMovableImageCategories()"
              :key="cat.quoteImageCategoryId"
              :value="cat.quoteImageCategoryId">
              {{ cat.name }}
            </option>
          </select>
        </div>
      </div>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold">{{ selectedCount }}</span> selected images will be moved to selected Category. Continue?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import QuoteService from './QuoteService'
import { QuoteNoSearchModal } from './components'
import { ConfirmModal } from '@/components/BulmaModal'
import QuoteImageCategory from './components/QuoteImageCategory.vue'
import QuoteImageCategoryModal from './components/QuoteImageCategoryModal.vue'
import { EventHubTypes } from '@/enums'
import VueDropzone from '@/components/VueDropzone'
import mimeTypes from 'mime-types'
import CommonApiService from '@/services/common'
import { isMobile } from '@/components/utils/helpers'
import { MobileImageSourceType } from '@/enums'

export default {
  name: 'QuoteImages',
  components: {
    QuoteImageCategoryModal,
    ConfirmModal,
    QuoteImageCategory,
    QuoteNoSearchModal,
    VueDropzone
  },
  props: {
    value: null
  },
  data() {
    return {
      size: 200,
      images: [],
      imageIds: null,
      imagesSelected: null,
      imagesWithInfo: [],
      areThumbnailsLoaded: false,
      isLoadingImages: false,
      addCategoryModalOpen: false,
      imageCategories: null,
      isDeleteAllModalActive: false,
      isCopyImageModalActive: false,
      isDownloadImagesModalActive: false,
      hasSeclectAll: false,
      refreshComponent: true,
      uploadedImage: false,
      selectedCategory: null,
      refreshCategory: false,
      isMoveSelectedImages: false,
      isDropzoneActive: false,
      queuedFileCount: 0,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_WEBAPI_URI}/quotes/images/upload/${this.value.quoteId}/${this.value.prefix}${this.value.quoteNo}${this.value.suffix}/${this.value.subQuoteNo}/${this.selectedCategory}`,
        dictDefaultMessage: 'Drop files here or click to upload',
        thumbnailWidth: 128,
        thumbnailHeight: 128,
        maxFilesize: 10,
        resizeWidth: 800,
        acceptedFiles: 'image/*',
        capture: 'image/',
        //uploadMultiple: false,
        headers: {
          Authorization: ''
        },
        accept: function (file, done) {
          const mimeType = file.type
          if (mimeType !== mimeTypes.contentType('jpeg')) {
            this.options.resizeMimeType = mimeTypes.contentType('jpeg')
            this.options.resizeQuality = 0.9
            done()
            return
          }
          done()
        },
        renameFile(file) {
          const extension = file.name.split('.').pop()
          if (extension !== 'jpg' || extension !== 'jpeg') {
            return `${file.name.substr(0, file.name.lastIndexOf('.'))}.${mimeTypes.extension(mimeTypes.contentType('jpeg'))}`
            // return file.name.substr(0, file.name.lastIndexOf('.')) + '.jpg'
          } else {
            return file.name
          }
        }
      }
    }
  },
  computed: {
    imageCount() {
      return this.imageIds ? this.imageIds.length : 0
    },
    isMobile() {
      return isMobile
    },
    hasSelected() {
      return this.imagesWithInfo.some((i) => i.isSelected)
    },
    selectedCount() {
      return this.imagesWithInfo.reduce(function (total, item) {
        return item.isSelected ? total + 1 : total
      }, 0)
    },
    canEditCategory() {
      return this.$userInfo.isSupportUser || this.$userInfo.isCustomerAdministrator
    },
    selectedImagesWithInfos() {
      return this.imagesWithInfo.filter((i) => i.isSelected)
    },
    mobileImageSourceType() {
      return MobileImageSourceType
    }
  },
  watch: {
    'dropzoneOptions.capture': function (newVal, oldVal) {
      // this.dropzoneOptions.capture = newVal
      // Reload vue-dropzone component here
      if (this.isDropzoneActive === true) {
        this.isDropzoneActive = false
        this.$nextTick(() => {
          this.isDropzoneActive = true
        })
      }
    }
  },
  async created() {
    this.dropzoneOptions.capture = isMobile.any() ? this.mobileImageSourceType.Gallery : this.mobileImageSourceType.Camera
    this.dropzoneOptions.headers.Authorization = `Bearer ${CommonApiService.getAuthToken()}`
    //  this.createDefaultImageCategories()
    await this.getImageCategories()
    this.getThumbnails()
  },
  methods: {
    async getThumbnailWithInfo(id, index) {
      var thumbnail = await QuoteService.getImageThumbnailWithInfo(id, this.size)
      return thumbnail
    },
    // async createDefaultImageCategories() {
    //   await QuoteService.createDefaultImageCategories()
    // },
    async getImageCategories() {
      this.imageCategories = await QuoteService.getQuoteImageCategories()
      //this.getThumbnails()
    },
    async getThumbnails() {
      this.isLoadingImages = true
      this.imagesWithInfo.splice(0, this.imagesWithInfo.length)
      // const vm = this

      const vm = this
      this.imageIds = await QuoteService.getImageIds(this.value.quoteId)
      // this.images = new Array(this.imageIds.length)
      // this.initSelectedIds(this.imageIds.length)
      const promises = this.imageIds.map(function (id, index) {
        // vm.getThumbnail(id, index)
        return vm.getThumbnailWithInfo(id, index)
      })
      this.imagesWithInfo = await Promise.all(promises)
      this.setPrimary()
      this.refreshCategory = !this.refreshCategory
      this.$eventHub.$emit(EventHubTypes.ImageCountChanged, this.imageIds.length)
      // this.$eventHub.$emit(`${EventHubTypes.ImageCountChanged}UpdateQuote`, this.imageIds.length)
      this.isLoadingImages = false
      this.areThumbnailsLoaded = true
    },
    // async saved(id) {
    //   // Callback function to update thumbnail after saving images in Painterro
    //   const thumbnail = this.imagesWithInfo.find((i) => i.quoteImageId === id)
    //   const updatedThumbnail = await this.getThumbnailWithInfo(id, this.size)
    //   thumbnail.imageContent = updatedThumbnail.imageContent
    //   // Force image source update
    //   // document.getElementById(thumbnail.quoteImageId).src = thumbnail.imageContent
    //   this.$eventHub.$emit(`${EventHubTypes.ImageCountChanged}UpdateQuote`, this.imageIds.length)
    // },
    updateImage() {
      this.getImageCategories()
      this.getThumbnails()
    },
    async moveSelectedImages(value) {
      if (value) {
        this.$showSpinner('Moving images')
        const selectedImageWithInfos = this.imagesWithInfo.filter((i) => i.isSelected)
        const selectedQuoteImageIDs = selectedImageWithInfos.map((i) => i.quoteImageId)
        await QuoteService.setQuoteImagesCategory(this.value.quoteId, this.selectedCategory, selectedQuoteImageIDs)
        await this.getThumbnails()
        this.isMoveSelectedImages = !value
        this.$hideSpinner('Moving images')
      } else this.isMoveSelectedImages = value
    },
    toggleImageCategory(value) {
      this.addCategoryModalOpen = value
    },
    showCopyImageDialog() {
      this.isCopyImageModalActive = true
    },
    showMoveImageDialog() {
      const movableImageCategories = this.getMovableImageCategories()
      if (movableImageCategories && movableImageCategories.length > 0) {
        this.selectedCategory = movableImageCategories[0].quoteImageCategoryId
      }
      this.isMoveSelectedImages = true
    },
    closeCopyImageModal() {
      this.isCopyImageModalActive = false
    },
    async copyImagesToQuote(quoteId) {
      this.isCopyImageModalActive = false
      this.$showSpinner('Copying images')
      // const vm = this
      // const selectedIds = this.imageIds.filter(function(id, index) {
      //   if (vm.imagesSelected[index]) {
      //     return id
      //   }
      // })
      const selectedIds = this.imagesWithInfo.filter((i) => i.isSelected).map((i) => i.quoteImageId)
      await QuoteService.copyImagesToQuote(quoteId, selectedIds)
      this.$notification.openNotificationWithType('success', 'Copy images', `${this.selectedCount} images copied`)
      this.$hideSpinner()
    },
    showDeletAllModal() {
      this.isDeleteAllModalActive = true
    },
    async deleteSelectedImages(confirm) {
      this.isDeleteAllModalActive = false
      this.refreshComponent = false

      if (confirm) {
        this.$showSpinner('Removing images')
        const count = this.selectedCount
        const deletedImageIds = this.imagesWithInfo.filter((i) => i.isSelected).map((i) => i.quoteImageId)
        await QuoteService.deleteMultipleImages(deletedImageIds)
        this.imagesWithInfo = this.imagesWithInfo.filter((i) => !i.isSelected)
        this.imageIds = this.imagesWithInfo.filter((i) => !i.isSelected).map((i) => i.quoteImageId)
        this.$hideSpinner()
        await this.$nextTick()
        this.refreshComponent = true
        this.getThumbnails()
        this.$eventHub.$emit(`${EventHubTypes.ImageCountChanged}UpdateQuote`, this.imagesWithInfo.length)
        this.$notification.openNotificationWithType('success', 'Delete images', `${count} images deleted`)
      }
    },
    setPrimary() {
      const hasPrimaryImage = this.imagesWithInfo.some((i) => i.isPrimary)
      if (!hasPrimaryImage && this.imagesWithInfo.length) {
        const firstImage = this.imagesWithInfo[0]
        firstImage.isPrimary = true
        QuoteService.setQuoteImageIsPrimary(firstImage.quoteImageId)
      }
    },
    showDownloadImagesModal() {
      this.isDownloadImagesModalActive = true
    },
    async downloadSelectedImages(confirm) {
      this.isDownloadImagesModalActive = false
      if (confirm) {
        const imageIds = this.imagesWithInfo.filter((i) => i.isSelected).map((i) => i.quoteImageId)
        let promises = []
        for (let i = 0; i < imageIds.length; i++) {
          const id = imageIds[i]
          promises.push(this.downloadImage(id))
          if (promises.length === 10) {
            await Promise.all(promises)
            promises = []
            await this.pause(1000)
          }
        }
      }
    },
    async pause(msec) {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, msec || 1000)
      })
    },
    async downloadImage(id) {
      const result = await QuoteService.downloadImage(id)
      const blob = new Blob([result.data], { type: result.headers['content-type'] })
      const disposition = result.headers['content-disposition']
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(disposition)
      let filename = ''
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '')
      }
      const link = document.createElement('a')
      link.style = 'display: none'
      const url = window.URL.createObjectURL(blob)
      link.href = url
      link.download = filename
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(url)
      delete link.href
    },
    setSelectedStatusAll(selected) {
      this.imagesWithInfo.forEach((i) => (i.isSelected = selected))
      this.hasSeclectAll = selected
    },
    dropzoneMounted() {
      this.$refs.quoteImageDropzone.getAcceptedFiles()
    },
    dropzoneFileAdded(file) {
      if (this.selectedCategory) {
        this.$refs.quoteImageDropzone.changeUrl(
          `${process.env.VUE_APP_WEBAPI_URI}/quotes/images/upload/${this.value.quoteId}/${this.value.prefix}${this.value.quoteNo}${this.value.suffix}/${this.value.subQuoteNo}/${this.selectedCategory}`
        )
      }
      this.isClearAllActive = true
      this.isUploading = true
    },
    dropzoneSuccess(file, response) {
      this.queuedFileCount++
      this.imagesWithInfo = []
    },
    dropzoneErrorMultiple(files, message, xhr) {},
    dropzoneSending(file, xhr, formData) {},
    dropzoneCompleteMultiple(files) {
      console.log(files.count)
    },
    async dropzoneQueueComplete(file, xhr, formData) {
      this.getThumbnails()
      this.isUploading = false
      this.$notification.openNotificationWithType('success', 'Upload images', `${this.queuedFileCount} images uploaded`)
      this.queuedFileCount = 0
      this.uploadedImage = true
      //this.getThumbnails()
      // this.imageIds = await QuoteService.getImageIds(this.value.quoteId)
      // this.$eventHub.$emit(EventHubTypes.ImageCountChanged, this.imageIds.length)
      // this.$eventHub.$emit(`${EventHubTypes.ImageCountChanged}UpdateQuote`, this.imageIds.length)
      this.$eventHub.$emit(`${EventHubTypes.ImageCountChanged}UpdateQuote`, this.imagesWithInfo.length)
      //
    },
    dropzoneTotalUploadProgress(totaluploadprogress, totalBytes, totalBytesSent) {},
    clearUploadedFile() {
      this.$refs.quoteImageDropzone.removeAllFiles()
      this.uploadedImage = false
    },
    async uploadImage() {
      this.isUploadImage = !this.isUploadImage
      this.isDropzoneActive = !this.isDropzoneActive
      if (this.imageCategories && this.imageCategories.length > 0) {
        this.selectedCategory = this.imageCategories[0].quoteImageCategoryId
      }

      if (!this.isUploadImage) {
        // this.getThumbnails()
        this.clearUploadedFile()
        this.refreshCategory = !this.refreshCategory
      }
    },
    async arrangeImagesOrder() {
      const imageIds = []
      for (const category of this.$refs.quoteImageCategories) {
        if (category.imagesWithInfo.length) {
          imageIds.push(...category.imagesWithInfo.map((obj) => obj.quoteImageId))
        }
      }
      if (imageIds) {
        await QuoteService.setQuoteImagesOrderIndex(this.value.quoteId, imageIds)
        // this.$eventHub.$emit(`${EventHubTypes.ImageCountChanged}UpdateQuote`, imageIds.length)
      }
    },
    getMovableImageCategories() {
      const selectedImageWithInfos = this.imagesWithInfo.filter((i) => i.isSelected)
      const selectedImageCategoryIDs = selectedImageWithInfos.map((i) => i.imageCategoryId)
      const uniqueImageCategoryIDs = Array.from(new Set(selectedImageCategoryIDs))
      const selectableImageCategories =
        uniqueImageCategoryIDs.length == 1 ? this.imageCategories.filter((i) => i.quoteImageCategoryId !== uniqueImageCategoryIDs[0]) : this.imageCategories
      return selectableImageCategories
    },
    onImageCategoriesChanged() {
      this.$notification.openNotificationWithType('success', 'Image Categories', 'Image categories updated')
      this.getImageCategories()
    }
  }
}
</script>

<style>
</style>
